<template>
  <div>
    <div class="row d-flex justify-content-end mr-1 mb-3">
      <b-btn
        variant="warning"
        href="#/master-data/airport"
        class="ml-auto text-white"
        style="width: 7em;"
        >Kembali</b-btn
      >
      <b-btn
        variant="primary"
        :href="'#/master-data/airport/' + $route.params.id + '/edit'"
        class="ml-2"
        style="width: 7em;"
        >Edit</b-btn
      >
    </div>
    <b-card header-tag="header">
      <template #header>
        <b-row>
          <b-col cols="10">
            <h5 class="mb-0">{{ $route.name }}</h5>
          </b-col>
        </b-row>
      </template>
      <b-row>
        <template v-for="(m, index) in model">
          <b-col
            :key="index"
            cols="4"
            v-if="
              (typeof m.disabled === 'undefined' || !m.disabled) &&
                m.type !== 'hidden'
            "
          >
            <detail-group-input
              :key="index"
              :type="m.type"
              :label="typeof m.label === 'string' ? m.label : null"
              :value="m.value"
              :id="index"
            >
              <template slot="content_is_active" slot-scope="defaultSlotScope">
                <span
                  :class="[
                    'badge',
                    defaultSlotScope.colData == 1
                      ? 'badge-v1-success'
                      : 'badge-v1-danger',
                  ]"
                  >{{
                    defaultSlotScope.colData == 1 ? "Aktif" : "Tidak Aktif"
                  }}</span
                >
              </template>
            </detail-group-input>
          </b-col>
        </template>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { setModelValue } from "@/_helpers";
import detailGroupInput from "@/components/DetailGroupInput.vue";
import Airport from "@/models/Airport.js";

const AirportModel = new Airport();

export default {
  components: {
    detailGroupInput,
  },
  watch: {
    "$route.params.id": function(val) {
      if (val !== undefined) {
        this.getDetail();
      }
    },
  },
  data() {
    return {
      isLoading: false,
      model: AirportModel.init(),
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.isLoading = true;
      AirportModel.find(this.$route.params.id)
        .then((resp) => {
          this.isLoading = false;
          setModelValue(this.model, resp);
        })
        .catch((error) => {
          if (process.env.NODE_ENV !== "production") {
            console.error(error);
          }
          this.isLoading = false;
        });
    },
  },
};
</script>
