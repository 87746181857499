var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row d-flex justify-content-end mr-1 mb-3"},[_c('b-btn',{staticClass:"ml-auto text-white",staticStyle:{"width":"7em"},attrs:{"variant":"warning","href":"#/master-data/airport"}},[_vm._v("Kembali")]),_c('b-btn',{staticClass:"ml-2",staticStyle:{"width":"7em"},attrs:{"variant":"primary","href":'#/master-data/airport/' + _vm.$route.params.id + '/edit'}},[_vm._v("Edit")])],1),_c('b-card',{attrs:{"header-tag":"header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-row',[_c('b-col',{attrs:{"cols":"10"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$route.name))])])],1)]},proxy:true}])},[_c('b-row',[_vm._l((_vm.model),function(m,index){return [(
            (typeof m.disabled === 'undefined' || !m.disabled) &&
              m.type !== 'hidden'
          )?_c('b-col',{key:index,attrs:{"cols":"4"}},[_c('detail-group-input',{key:index,attrs:{"type":m.type,"label":typeof m.label === 'string' ? m.label : null,"value":m.value,"id":index},scopedSlots:_vm._u([{key:"content_is_active",fn:function(defaultSlotScope){return [_c('span',{class:[
                  'badge',
                  defaultSlotScope.colData == 1
                    ? 'badge-v1-success'
                    : 'badge-v1-danger' ]},[_vm._v(_vm._s(defaultSlotScope.colData == 1 ? "Aktif" : "Tidak Aktif"))])]}}],null,true)})],1):_vm._e()]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }